import gql from 'graphql-tag';

export const getProjects = gql`
  query getProjects {
    project(order_by: { label: asc }, where: { archived: { _eq: false } }) {
      id
      label
      path
      site
      calibration_data
      updated_at
    }
  }
`;

export const getProject = gql`
  query getProject($id: Int!) {
    project_by_pk(id: $id) {
      id
      label
      path
      site
      site_plan
      source_data
      archived
      calibration_data
      updated_at
      buildos_project_id
    }
  }
`;

export const getPrintForProject = gql`
  query getPrint($projectId: Int!, $projectUpdatedAt: timestamptz!) {
    print(
      where: {
        project_id: { _eq: $projectId }
        project_updated_at: { _eq: $projectUpdatedAt }
      }
    ) {
      id
      label
      project_id
      site
      calibration_data
      created_at
      updated_at
      processing_is_success
      itemized_optimized_site_plan
      smep_summary
    }
  }
`;

export const updateProject = gql`
  mutation updateProject($id: Int, $calibration_data: String) {
    update_project(
      where: { id: { _eq: $id } }
      _set: { calibration_data: $calibration_data }
    ) {
      affected_rows
    }
  }
`;
